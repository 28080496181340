// import { NumberFormat, options } from 'o365.libs.vue-number-format.3.2.0.js';
import { NumberFormat, options } from 'vue-number-format';
import { userSession } from 'o365.modules.configs.ts';
const vueNumberOptions = { ...options };

const REGEX_EXPRESSIONS = {
    numberFormatRegex: /^([^\d,. ']*)(?:[\d]+|\d{1,3}(?:(?:(')\d{3})+|(?:(,)\d{3})+|(?:( )\d{3})+))(?:(\.)\d+)?([^\d,. ']*)$/,
};
Object.freeze(REGEX_EXPRESSIONS);

class Number {
    static formatNumber(value) {
        return `${value}%`;
    }

    static formatFileSize(size) {
        if (size == null) { return size; }
        var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
    }

    static format(pValue, pFormat) {
        const vOptions = Number.getVueNumberFormatOptions(pFormat);

        if (!vOptions) { return pValue; }

        const defaultOptions = { ...vueNumberOptions, ...vOptions };
        const formater = new NumberFormat(defaultOptions);


        const multiplier = defaultOptions.multiplier ?? defaultOptions.rawValueDivisor;
        if (multiplier) { pValue = pValue * multiplier; }

        if (defaultOptions?.precision != null && pValue?.toFixed) {
            pValue = pValue.toFixed(defaultOptions.precision);
        }

        return formater.format(pValue);
    }

    // vue-number-format options
    static getVueNumberFormatOptions(pFormat) {
        const vOptions = Number.getUserFormatOptions(pFormat);
        if (!vOptions) { return; }
        const vFormatOptions = {};

        if (vOptions.groupSeparator) { vFormatOptions.separator = vOptions.groupSeparator.charCodeAt(0) == 160 ? '' : vOptions.groupSeparator; }
        if (vOptions.decimalSeparator) { vFormatOptions.decimal = vOptions.decimalSeparator; }
        // if (vOptions.decimalSeparator && vOptions.decimalSeparator == ',') { vFormatOptions.decimalCharacterAlternative = '.'; }
        // if (vOptions.decimalSeparator && vOptions.decimalSeparator == '.') { vFormatOptions.decimalCharacterAlternative = ','; }
        if (vOptions.suffix) { vFormatOptions.suffix = vOptions.suffix; }
        if (vOptions.decimals) { vFormatOptions.precision = vOptions.decimals; vFormatOptions.minimumFractionDigits = vOptions.decimals }
        if (vOptions.decimals !== undefined && vOptions.decimals === 0) { vFormatOptions.precision = 0; }

        if (vOptions.multiplier) { vFormatOptions.rawValueDivisor = vOptions.multiplier; }
        if (vOptions.prefix) { vFormatOptions.prefix = vOptions.prefix; }
        if (vOptions.precision) { vFormatOptions.precision = vOptions.precision; }
        if (vOptions.reverseFill) { vFormatOptions.reverseFill = vOptions.reverseFill; }
        if (vOptions.prefill) { vFormatOptions.prefill = vOptions.prefill; }
        if (vOptions.minimumFractionDigits) { vFormatOptions.minimumFractionDigits = vOptions.minimumFractionDigits; }
        // vFormatOptions.emptyInputBehavior = 'null';
        // vFormatOptions.modifyValueOnWheel = false;

        return vFormatOptions;
    }


    /**
   * Gets formatting options literal by parsing the pFormat, while preferring any existing options sent to pOpts
   * @return {null|object} Null if not a valid number format mask, else returns the options literal
   */
    static getFormatOptionsFromMask(pFormat, pOptions) {
        if(pFormat.indexOf("#") > -1){
            return this.getFormatOptionsFromMaskSharp(pFormat, pOptions);
        }
        let vFormatMatch, vGroupSeparator;
        vFormatMatch = pFormat.match(REGEX_EXPRESSIONS.numberFormatRegex);
        if (vFormatMatch) {
            if (!pOptions) { pOptions = {}; }
            if (vFormatMatch[1]) { pOptions.prefix = vFormatMatch[1]; }
            if (!Object.hasOwnProperty(pOptions, 'groupSeperator')) {
                vGroupSeparator = vFormatMatch[2] || vFormatMatch[3] || vFormatMatch[4];
                if (vGroupSeparator) { pOptions.groupSeparator = vGroupSeparator; }
            }
            if (vFormatMatch[5]) {
                if (!Object.hasOwnProperty(pOptions, 'decimals')) {
                    pOptions.decimals = (pFormat.split(vFormatMatch[5])[1]).replace(/[^\d]/g, '').length;
                }
                if (!pOptions.decimalSeparator) { pOptions.decimalSeparator = vFormatMatch[5]; }
            } else if (!Object.hasOwnProperty(pOptions, 'decimals')) {
                pOptions.decimals = 0;
            }
            if (vFormatMatch[6]) { pOptions.suffix = vFormatMatch[6]; }
            return pOptions;
        } else {
            return null;
        }
    };
    static getFormatOptionsFromMaskSharp(pFormat, pOptions) {
        const vFormatMatch = pFormat.match(REGEX_EXPRESSIONS.numberFormatRegex);
        //   let vFormatMatch, vGroupSeparator;
        if (!pOptions) { pOptions = {}; }
        const vTmp = pFormat.split(".");
        if (vTmp[1]) {
            pOptions['precision'] = vTmp[1].length;
            pOptions['minimumFractionDigits'] = this.countChars(vTmp[1], 0);
        }

        pOptions['reverseFill'] = false;
        pOptions['prefill'] = false;
        if (vFormatMatch) {
            if (vFormatMatch[1]) {
                pOptions.prefix = vFormatMatch[1];
            }
            const vGroupSeparator = vFormatMatch[2] || vFormatMatch[3] || vFormatMatch[4];
            if (vGroupSeparator) { pOptions.groupSeparator = vGroupSeparator; }
            if (vFormatMatch[5]) {
                if (!Object.hasOwnProperty(pOptions, 'decimals')) {
                    pOptions.decimals = (pFormat.split(vFormatMatch[5])[1]).replace(/[^\d]/g, '').length;
                }
                if (!pOptions.decimalSeparator) { pOptions.decimalSeparator = vFormatMatch[5]; }
            } else if (!Object.hasOwnProperty(pOptions, 'decimals')) {
                pOptions.decimals = 0;
            }
        }



        return pOptions;

    };

    static countChars(pValue,char){
        let count = 0;
        Array.prototype.forEach.call(pValue, function(c) {
            if(c == char) count ++;
        })
        return count;
    }



    /**
     * Gets formatting options literal by looking at formats and userSession, and by parsing the pFormat
     * @return {null|object} Null if not a valid number format mask, else returns the options literal
     */
    static getUserFormatOptions(pFormat) {
        // const userSession = userSession;
        let vFormat, vOptions;
        if (userSession) {
            vFormat = userSession.formats[pFormat] || pFormat;
            vOptions = {
                decimalSeparator: userSession.decimalSeparator,
                groupSeparator: userSession.groupSeparator === ',' ? ' ' : userSession.groupSeparator,
            };
        } else {
            vFormat = pFormat;
            if (vFormat) { vOptions = {}; }
        }
        if (vFormat) {
            vOptions = Number.getFormatOptionsFromMask(vFormat, vOptions);
            // if (vOptions && !vOptions.decimals && (vOptions.prefix === '%' || vOptions.suffix === '%')) {
            if (vOptions && (vOptions.prefix === '%' || vOptions.suffix === '%')) {
                vOptions.multiplier = 100;
            }
            if (vOptions && (vOptions.prefix === 'k' || vOptions.suffix === 'k')) {
                vOptions.multiplier = 0.001;
            }
        }
        return vOptions;
    }
}

export default Number; 
